@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

body, html {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
  font-family: inherit;
  box-sizing: inherit;
}

*:active {
  border: none;
  outline: none;
}
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  padding: 8px 12px;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: var(--primary);
  }
}

.pagination .active a {
  background-color: var(--accent);
  border: none;
  cursor: default;
  &:hover {
    background-color: var(--accent);
  }
}
